var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$root.$data.me.AllowAnalytics
    ? _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          !_vm.apOptions || _vm.apOptions.length === 0
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _vm.apOptions === null && !_vm.apError
                        ? [
                            _vm._v(" Ophalen van beschikbare periodes "),
                            _c("b-spinner", { attrs: { label: "Laden" } })
                          ]
                        : _vm._e(),
                      _vm.apOptions && _vm.apOptions.length === 0
                        ? _c("span", [_vm._v("Geen gegevens beschikbaar")])
                        : _vm._e(),
                      _vm.apError
                        ? _c("span", [_vm._v(_vm._s(_vm.apError))])
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.apOptions && _vm.apOptions.length > 0
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("page-snippet", { attrs: { name: "analytics" } }),
                      _c(
                        "b-card",
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [_vm._v(" Filters / Opties ")]
                          ),
                          _c(
                            "b-container",
                            { staticClass: "p-0", attrs: { fluid: "" } },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-4",
                                      attrs: {
                                        cols: "12",
                                        md: "6",
                                        lg: "6",
                                        xl: "4"
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "mr-2" }, [
                                        _vm._v("Bekijk:")
                                      ]),
                                      _c(
                                        "b-button-group",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              class: {
                                                active:
                                                  _vm.view == "top-lists" &&
                                                  _vm.target == "Quantity"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.view = "top-lists"
                                                  _vm.target = "Quantity"
                                                  _vm.filterSerial++
                                                }
                                              }
                                            },
                                            [
                                              _vm.sortDirection == "desc"
                                                ? _c("span", [_vm._v("Top")])
                                                : _vm._e(),
                                              _vm.sortDirection == "asc"
                                                ? _c("span", [_vm._v("Bodem")])
                                                : _vm._e(),
                                              _vm._v(" aantal ")
                                            ]
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              class: {
                                                active:
                                                  _vm.view == "top-lists" &&
                                                  _vm.target == "ProviderIncome"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.view = "top-lists"
                                                  _vm.target = "ProviderIncome"
                                                  _vm.filterSerial++
                                                }
                                              }
                                            },
                                            [
                                              _vm.sortDirection == "desc"
                                                ? _c("span", [_vm._v("Top")])
                                                : _vm._e(),
                                              _vm.sortDirection == "asc"
                                                ? _c("span", [_vm._v("Bodem")])
                                                : _vm._e(),
                                              _vm._v(" inkomsten ")
                                            ]
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              class: {
                                                active: _vm.view == "table"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.view = "table"
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " Bekijk/exporteer transacties "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  false
                                    ? _c(
                                        "b-col",
                                        {
                                          staticClass: "mb-4",
                                          attrs: {
                                            cols: "12",
                                            md: "6",
                                            lg: "6",
                                            xl: "4"
                                          }
                                        },
                                        [
                                          _c("div", { staticClass: "mr-2" }, [
                                            _vm._v("Sorteer:")
                                          ]),
                                          _c(
                                            "b-button-group",
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  class: {
                                                    active:
                                                      _vm.sortDirection == "asc"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.sortDirection = "asc"
                                                    }
                                                  }
                                                },
                                                [_vm._v(" Oplopend ")]
                                              ),
                                              _c(
                                                "b-button",
                                                {
                                                  class: {
                                                    active:
                                                      _vm.sortDirection ==
                                                      "desc"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.sortDirection = "desc"
                                                    }
                                                  }
                                                },
                                                [_vm._v(" Aflopend ")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-4",
                                      attrs: {
                                        cols: "12",
                                        md: "6",
                                        lg: "6",
                                        xl: "4"
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "mr-2" }, [
                                        _vm._v("Periode:")
                                      ]),
                                      _c(
                                        "span",
                                        { staticClass: "mt-2" },
                                        [
                                          _c("b-form-select", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              options: _vm.apOptions,
                                              size: "sm"
                                            },
                                            model: {
                                              value: _vm.filter.apFrom,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.filter,
                                                  "apFrom",
                                                  $$v
                                                )
                                              },
                                              expression: "filter.apFrom"
                                            }
                                          }),
                                          _vm._v(" - "),
                                          _c("b-form-select", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              options: _vm.apOptions,
                                              size: "sm"
                                            },
                                            model: {
                                              value: _vm.filter.apTo,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.filter,
                                                  "apTo",
                                                  $$v
                                                )
                                              },
                                              expression: "filter.apTo"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-4",
                                      attrs: {
                                        cols: "12",
                                        md: "6",
                                        lg: "6",
                                        xl: "4"
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "mr-2" }, [
                                        _vm._v("Resultaten:")
                                      ]),
                                      _c(
                                        "b-button-group",
                                        { staticClass: "mr-2" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              class: {
                                                active: _vm.perPage == 5
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.perPage = 5
                                                }
                                              }
                                            },
                                            [_vm._v(" 5 ")]
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              class: {
                                                active: _vm.perPage == 10
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.perPage = 10
                                                }
                                              }
                                            },
                                            [_vm._v(" 10 ")]
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              class: {
                                                active: _vm.perPage == 25
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.perPage = 25
                                                }
                                              }
                                            },
                                            [_vm._v(" 25 ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-4",
                                      attrs: {
                                        cols: "12",
                                        md: "6",
                                        lg: "6",
                                        xl: "4"
                                      }
                                    },
                                    [
                                      _vm._v(" Nummer formaat:"),
                                      _c("br"),
                                      _c(
                                        "b-button-group",
                                        { staticClass: "mr-2" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              class: {
                                                active:
                                                  _vm.numberLocale == "intl"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.numberLocale = "intl"
                                                }
                                              }
                                            },
                                            [_vm._v(" 1 000.00 ")]
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              class: {
                                                active: _vm.numberLocale == "en"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.numberLocale = "en"
                                                }
                                              }
                                            },
                                            [_vm._v(" 1,000.00 ")]
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              class: {
                                                active: _vm.numberLocale == "nl"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.numberLocale = "nl"
                                                }
                                              }
                                            },
                                            [_vm._v(" 1.000,00 ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-4",
                                      attrs: {
                                        cols: "12",
                                        md: "6",
                                        lg: "4",
                                        xl: "4"
                                      }
                                    },
                                    [
                                      _vm._v(" Tracks: "),
                                      _c("ApiLookup", {
                                        attrs: {
                                          source: "analytics/find-tracks",
                                          filter: _vm.filter,
                                          context: "RoyaltyTransactions"
                                        },
                                        model: {
                                          value: _vm.filter.selectedTracks,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filter,
                                              "selectedTracks",
                                              $$v
                                            )
                                          },
                                          expression: "filter.selectedTracks"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-4",
                                      attrs: {
                                        cols: "12",
                                        md: "6",
                                        lg: "4",
                                        xl: "4"
                                      }
                                    },
                                    [
                                      _vm._v(" Albums: "),
                                      _c("ApiLookup", {
                                        attrs: {
                                          source: "analytics/find-albums",
                                          filter: _vm.filter,
                                          context: "RoyaltyTransactions"
                                        },
                                        model: {
                                          value: _vm.filter.selectedAlbums,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filter,
                                              "selectedAlbums",
                                              $$v
                                            )
                                          },
                                          expression: "filter.selectedAlbums"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-4",
                                      attrs: {
                                        cols: "12",
                                        md: "6",
                                        lg: "4",
                                        xl: "4"
                                      }
                                    },
                                    [
                                      _vm._v(" Uitvoerend artiest: "),
                                      _c("ApiLookup", {
                                        attrs: {
                                          source: "analytics/find-attributions",
                                          filter: _vm.filter,
                                          context: "RoyaltyTransactions"
                                        },
                                        model: {
                                          value:
                                            _vm.filter.selectedAttributions,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filter,
                                              "selectedAttributions",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filter.selectedAttributions"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-4",
                                      attrs: {
                                        cols: "12",
                                        md: "6",
                                        lg: "4",
                                        xl: "4"
                                      }
                                    },
                                    [
                                      _vm._v(" Portals: "),
                                      _c("ApiLookup", {
                                        attrs: {
                                          source: "analytics/find-outlets",
                                          filter: _vm.filter,
                                          context: "RoyaltyTransactions"
                                        },
                                        model: {
                                          value: _vm.filter.selectedOutlets,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filter,
                                              "selectedOutlets",
                                              $$v
                                            )
                                          },
                                          expression: "filter.selectedOutlets"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-4",
                                      attrs: {
                                        cols: "12",
                                        md: "6",
                                        lg: "4",
                                        xl: "4"
                                      }
                                    },
                                    [
                                      _vm._v(" Landen: "),
                                      _c("ApiLookup", {
                                        attrs: {
                                          source: "analytics/find-countries",
                                          filter: _vm.filter,
                                          context: "RoyaltyTransactions"
                                        },
                                        model: {
                                          value: _vm.filter.selectedCountries,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filter,
                                              "selectedCountries",
                                              $$v
                                            )
                                          },
                                          expression: "filter.selectedCountries"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-4",
                                      attrs: {
                                        cols: "12",
                                        md: "6",
                                        lg: "4",
                                        xl: "4"
                                      }
                                    },
                                    [
                                      _vm._v(" Label "),
                                      _c("ApiLookup", {
                                        attrs: {
                                          source:
                                            "analytics/find-statement-refs",
                                          filter: _vm.filter
                                        },
                                        model: {
                                          value: _vm.filter.selectedRefs,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filter,
                                              "selectedRefs",
                                              $$v
                                            )
                                          },
                                          expression: "filter.selectedRefs"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "mr-2",
                                          attrs: { variant: "primary" },
                                          on: {
                                            click: function($event) {
                                              _vm.filterSerial++
                                            }
                                          }
                                        },
                                        [_vm._v("Toepassen")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "secondary" },
                                          on: { click: _vm.clearFilters }
                                        },
                                        [_vm._v("Leeg maken")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.view == ""
            ? _c(
                "b-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "b-col",
                    [
                      _c("b-alert", { attrs: { show: "" } }, [
                        _vm._v(
                          'Kies bovenaan een optie: "Top aantal", "Top inkomsten" or "Bekijk/exporteer transacties"'
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.view == "table" && _vm.apOptions.length > 0
            ? _c(
                "b-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "b-col",
                    [
                      _c("RoyaltyTransactionTable", {
                        attrs: {
                          watch: _vm.filterSerial,
                          filter: _vm.filter,
                          perPage: _vm.perPage,
                          numberLocale: _vm.numberLocale,
                          sortDirection: _vm.sortDirection
                        },
                        on: {
                          "item-click": function($event) {
                            return _vm.filterItem($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.view == "top-lists" && _vm.apOptions.length > 0
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "mt-4",
                      attrs: { cols: "12", md: "6", lg: "4", xl: "3" }
                    },
                    [
                      _c("RoyaltyAnalyticsList", {
                        attrs: {
                          sortDirection: _vm.sortDirection,
                          canSelect: true,
                          watch: _vm.filterSerial,
                          numberLocale: _vm.numberLocale,
                          filter: _vm.filter,
                          target: _vm.target,
                          perPage: _vm.perPage,
                          aggName: "track",
                          title: "Tracks",
                          columnTitle: "Track"
                        },
                        on: {
                          "item-click": function($event) {
                            return _vm.filterItem($event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "mt-4",
                      attrs: { cols: "12", md: "6", lg: "4", xl: "3" }
                    },
                    [
                      _c("RoyaltyAnalyticsList", {
                        attrs: {
                          sortDirection: _vm.sortDirection,
                          canSelect: true,
                          watch: _vm.filterSerial,
                          numberLocale: _vm.numberLocale,
                          filter: _vm.filter,
                          target: _vm.target,
                          perPage: _vm.perPage,
                          aggName: "album",
                          title: "Albums",
                          columnTitle: "Album"
                        },
                        on: {
                          "item-click": function($event) {
                            return _vm.filterItem($event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "mt-4",
                      attrs: { cols: "12", md: "6", lg: "4", xl: "3" }
                    },
                    [
                      _c("RoyaltyAnalyticsList", {
                        attrs: {
                          sortDirection: _vm.sortDirection,
                          canSelect: true,
                          watch: _vm.filterSerial,
                          numberLocale: _vm.numberLocale,
                          filter: _vm.filter,
                          target: _vm.target,
                          perPage: _vm.perPage,
                          aggName: "attribution",
                          title: "Artiest",
                          columnTitle: "Artiest"
                        },
                        on: {
                          "item-click": function($event) {
                            return _vm.filterItem($event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "mt-4",
                      attrs: { cols: "12", md: "6", lg: "4", xl: "3" }
                    },
                    [
                      _c("RoyaltyAnalyticsList", {
                        attrs: {
                          sortDirection: _vm.sortDirection,
                          canSelect: true,
                          watch: _vm.filterSerial,
                          numberLocale: _vm.numberLocale,
                          filter: _vm.filter,
                          target: _vm.target,
                          perPage: _vm.perPage,
                          aggName: "reference",
                          title: "Labels",
                          columnTitle: "Label"
                        },
                        on: {
                          "item-click": function($event) {
                            return _vm.filterItem($event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "mt-4",
                      attrs: { cols: "12", md: "6", lg: "4", xl: "3" }
                    },
                    [
                      _c("RoyaltyAnalyticsList", {
                        attrs: {
                          sortDirection: _vm.sortDirection,
                          canSelect: true,
                          watch: _vm.filterSerial,
                          numberLocale: _vm.numberLocale,
                          filter: _vm.filter,
                          target: _vm.target,
                          perPage: _vm.perPage,
                          aggName: "outlet",
                          title: "Portals",
                          columnTitle: "Portal"
                        },
                        on: {
                          "item-click": function($event) {
                            return _vm.filterItem($event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "mt-4",
                      attrs: { cols: "12", md: "6", lg: "4", xl: "3" }
                    },
                    [
                      _c("RoyaltyAnalyticsList", {
                        attrs: {
                          sortDirection: _vm.sortDirection,
                          canSelect: true,
                          watch: _vm.filterSerial,
                          numberLocale: _vm.numberLocale,
                          filter: _vm.filter,
                          target: _vm.target,
                          perPage: _vm.perPage,
                          aggName: "country",
                          title: "Landen",
                          columnTitle: "Land"
                        },
                        on: {
                          "item-click": function($event) {
                            return _vm.filterItem($event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "mt-4",
                      attrs: { cols: "12", md: "6", lg: "4", xl: "3" }
                    },
                    [
                      _c("RoyaltyAnalyticsList", {
                        attrs: {
                          sortDirection: _vm.sortDirection,
                          canSelect: true,
                          watch: _vm.filterSerial,
                          numberLocale: _vm.numberLocale,
                          filter: _vm.filter,
                          target: _vm.target,
                          perPage: _vm.perPage,
                          aggName: "accountingPeriod",
                          title: "Periodes",
                          columnTitle: "Periode"
                        },
                        on: {
                          "item-click": function($event) {
                            return _vm.filterItem($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }